import View from "../../../../vendor/framework/view";
import IndexService from "../../services/index";
import Auth from "../../services/auth";
import RuleService from "../../services/admin/rule";

class Index extends View
{
    boot() {
        this.state = {
			appName:'面试题后台管理',
        	user:{},
            menus:[]
        }
    }

    service() {
		return IndexService;
	}

	componentDidMount() {
		 /* global layui */
		let auth = this.server.getService(Auth);

		this.server.getService(RuleService).getMenu((data) => {
			this.setState({
				user:auth.getService(Auth).getUser(),
				menus:data
			}, () => {
				layui.config({
					base:'/layuiadmin/'
				}).extend({
					index:'./lib/index',
				}).use(['index'], () => {

				})
			})
		}, true)
	}

	render() {
		return (
			<div id="LAY_app">
				<div className="layui-layout layui-layout-admin">
					<div className="layui-header">
						<ul className="layui-nav layui-layout-left">
							<li className="layui-nav-item layadmin-flexible" lay-unselect="true">
								<a href="#!" layadmin-event="flexible"  title="侧边伸缩" rel="noopener noreferrer">
									<i className="layui-icon layui-icon-shrink-right" id="LAY_app_flexible"></i>
								</a>
							</li>
							<li className="layui-nav-item layui-hide-xs" lay-unselect="true">
								<a href="http://www.layui.com/admin/" target="_blank" title="前台"  rel="noopener noreferrer">
									<i className="layui-icon layui-icon-website"></i>
								</a>
							</li>
							<li className="layui-nav-item" lay-unselect="true">
								<a href="#!" layadmin-event="refresh" title="刷新" rel="noopener noreferrer">
									<i className="layui-icon layui-icon-refresh-3"></i>
								</a>
							</li>
							<li className="layui-nav-item layui-hide-xs" lay-unselect="true">

							</li>
						</ul>
						<ul className="layui-nav layui-layout-right" lay-filter="layadmin-layout-right">

							<li className="layui-nav-item" lay-unselect="true">

							</li>
							<li className="layui-nav-item layui-hide-xs" lay-unselect="true">
								<a href="#!" layadmin-event="theme" rel="noopener noreferrer">
									<i className="layui-icon layui-icon-theme"></i>
								</a>
							</li>
							<li className="layui-nav-item layui-hide-xs" lay-unselect="true">
								<a href="#!" layadmin-event="note" rel="noopener noreferrer">
									<i className="layui-icon layui-icon-note"></i>
								</a>
							</li>
							<li className="layui-nav-item layui-hide-xs" lay-unselect="true">
								<a href="#!" layadmin-event="fullscreen" rel="noopener noreferrer">
									<i className="layui-icon layui-icon-screen-full"></i>
								</a>
							</li>
							<li className="layui-nav-item" lay-unselect="true">
								<a href="#!">
									<cite>{this.state.user.username}</cite>
								</a>
								<dl className="layui-nav-child">
									<dd style={{textAlign: 'center',cursor :'pointer'}}><a herf="#!" onClick={e => this.checkOut()} rel="noopener noreferrer">退出</a></dd>
								</dl>
							</li>

							<li className="layui-nav-item layui-hide-xs" lay-unselect="true">
								<a href="#!" layadmin-event="about"> <i className="layui-icon layui-icon-more-vertical"></i></a>
							</li>
							<li className="layui-nav-item layui-show-xs-inline-block layui-hide-sm" lay-unselect="true">
								<a href="#!" layadmin-event="more" rel="noopener noreferrer"><i className="layui-icon layui-icon-more-vertical"></i></a>
							</li>
						</ul>
					</div>

					<div className="layui-side layui-side-menu">
						<div className="layui-side-scroll">
							<div className="layui-logo" lay-href="/admin/console">
								<span>{this.state.appName}</span>
							</div>
							<ul className="layui-nav layui-nav-tree" lay-shrink="all" id="LAY-system-side-menu" lay-filter="layadmin-system-side-menu">
							{this.getFirst()}
							</ul>
						</div>
					</div>

					<div className="layadmin-pagetabs" id="LAY_app_tabs">
						<div className="layui-icon layadmin-tabs-control layui-icon-prev" layadmin-event="leftPage"></div>
						<div className="layui-icon layadmin-tabs-control layui-icon-next" layadmin-event="rightPage"></div>
						<div className="layui-icon layadmin-tabs-control layui-icon-down">
							<ul className="layui-nav layadmin-tabs-select" lay-filter="layadmin-pagetabs-nav">
								<li className="layui-nav-item" lay-unselect="true">
									<a href="#!">&nbsp;</a>
									<dl className="layui-nav-child layui-anim-fadein">
										<dd layadmin-event="closeThisTabs"><a href="#!">关闭当前标签页</a></dd>
										<dd layadmin-event="closeOtherTabs"><a href="#!">关闭其它标签页</a></dd>
										<dd layadmin-event="closeAllTabs"><a href="#!">关闭全部标签页</a></dd>
									</dl>
								</li>
							</ul>
						</div>
						<div className="layui-tab" lay-unauto="true" lay-allowclose="true" lay-filter="layadmin-layout-tabs">
							<ul className="layui-tab-title" id="LAY_app_tabsheader">
								<li lay-id="/admin/console" className="layui-this" lay-attr="/admin/console"><i className="layui-icon layui-icon-home"></i></li>
							</ul>
						</div>
					</div>

					<div className="layui-body" id="LAY_app_body">
						<div className="layadmin-tabsbody-item layui-show">
							<iframe src="/admin/console" frameBorder="0" className="layadmin-iframe" title="navigation"></iframe>
						</div>
					</div>
					<div className="layadmin-body-shade" layadmin-event="shade"></div>
				</div>
			</div>
		)
	}

	//一级菜单
	getFirst() {
		return 	this.state.menus.map((value, index) => {
					if (value.children && value.children.length) {
						return  (
							<li key={index} data-name="" className="layui-nav-item">
								<a href="#!" lay-tips={value.name} lay-direction={index}>
									<i className={'layui-icon iconfont '+value.icon.className}></i>
									<cite>{value.name}</cite>
									<span className="layui-nav-more"></span>
								</a>
								<dl className="layui-nav-child">
									{this.getSecond(value.children, index + 1)}
								</dl>
							</li>
						)
					}
					return (
						<li key={index} data-name="" className="layui-nav-item">
							<a href="#!" lay-tips={value.name} onClick={value.path || function() {}} lay-direction={index}>
								<i className={'layui-icon iconfont '+value.icon.className}></i>
								<cite>{value.name}</cite>
								<span className="layui-nav-more"></span>
							</a>
						</li>
					)
	        	})
	}

	//二级菜单
	getSecond(children, find) {
		if (children && children.length) {
			return children.map((value, index) => {
				index = (index + 1) * find;
				var href = value.path && (!value.children || !value.children.length) ? value.path : undefined;
				var dd;
				if (typeof href === 'function') {
					dd = (
						<dd key={index}>
							<a href="#!" onClick={href}>{value.name}{this.getMore(value.children)}</a>
							{this.getThird(value.children, index)}
						</dd>
					);
				} else {
					dd = (
						<dd key={index}>
							<a href="#!" lay-href={href}>{value.name}{this.getMore(value.children)}</a>
							{this.getThird(value.children, index)}
						</dd>
					);
				}
				return dd;
			})
		}
	}

	//三级菜单
	getThird(children, find) {
		if (children && children.length) {
			return (
				<dl className="layui-nav-child">
					{
						children.map((value, index) => {
							index = (index + 1) * find;
							var dd;
							if (typeof value.path === 'function') {
								dd = (
									<dd key={index}><a href="#!" onClick={value.path}>{value.name}</a></dd>
								)
							} else {
								dd = (
									<dd key={index}><a href="#!" lay-href={value.path}>{value.name}</a></dd>
								)
							}
							return dd;
						})
					}
				</dl>
			)
		}
	}

	//是否有子菜单
	getMore(children) {
		if (children && children.length) {
			return (<span className="layui-nav-more"></span>);
		}
	}

	checkOut() {
		this.server.getService(Auth).logout();
		this.app.redirect('/admin/login');
	}
}
export default Index;
