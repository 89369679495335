import { Fragment } from 'react'
import style from './slides.module.css';
import View from "../../../../vendor/framework/view";
import File from "../../../../vendor/framework/file";
import Auth from "../../services/auth";

class Image extends View
{
    file = new File();

    boot(props) {
        this.state = {
            src:props.src || '',
            inputName:props.inputName,
            canDel:props.canDel,
            btnClass:props.btnClass || 'chooseBtnUpload',
        }
    }

    componentWillReceiveProps(props) {
        this.setState({
            src:props.src || ''
        })
    }

    componentDidMount() {
        /* global layui,layer */
        let self = this,
            auth = new Auth();
        layui.use(['form'], () => {
            let config = {
                btn:'.'+this.state.btnClass,
                fileType:'image',
                fileErr(msg) {
                    layer.msg(msg);
                },
                success(res) {
                    if (res.code === 200) {
                        self.handleImage(self.file.make(res.data.url));
                    } else {
                        layer.msg(res.message);
                    }
                },
                headers:{
                    Authorization: 'Bearer ' + auth.getToken()
                }
            }
            if (self.props.max) {
                config.maxSize = self.props.max;
                config.fileErr = (msg) => {
                    layer.msg(msg);
                }
            }

            //上传图片
            this.file.upload(config);
        })
    }

    render() {
        var showPic,
            del;

        if (this.state.src) {
            if (this.state.canDel) {
                del = (
                    <span className={"layui-icon layui-icon-close "+style["close-btn"]} onClick={e => this.handleImage()} title={"删除"}></span>
                )
            }
            showPic = (
                <div className={style.imgBox}>
                    <div className={style.imgDiv}>
                        <img alt={""} className="psimg" src={this.state.src} />
                        <input type="hidden" name={this.state.inputName} value={this.file.filter(this.state.src)} />
                    </div>
                    {del}
                </div>
            )
        }

        return (
            <Fragment>
                <button style={{float: 'left'}} type="button" className={"layui-btn "+this.state.btnClass}>选择图片</button>
                {
                    this.props.tipExtentions && this.props.tipExtentions()
                }
                <div style={{height: 'auto',width: '100%',float: 'left',marginTop: '5px'}} id="images">
                    {showPic}
                </div>
            </Fragment>
        );
    }

    handleImage(src = '') {
        this.props.handle && this.props.handle(src, this.state.inputName);
        this.setState({
            src:src
        })
    }

}

export default Image;
