import TypeListLayout from '../layout';
import Base from "../../base";

export default class TypeList extends Base
{
    render() {
        return (
            <TypeListLayout />
        );
    }
}
