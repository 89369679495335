import React, { Fragment } from "react";

class TableSort extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {
            show:props.show !== undefined ? props.show : false,
            asc:props.asc,
            desc:props.desc,
            name:props.name
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState(nextProps);
    }

    render() {
        var sort, asc, desc, name;
        if (this.state.name) {
            name = (
                <span>{this.state.name}</span>
            )
        }
        if (this.state.show) {
            if (this.state.asc !== undefined) {
                asc = (
                    <i className="layui-edge layui-table-sort-asc" title="升序" onClick={e => this.handle(this.state.asc)}></i>
                )
            }
            if (this.state.desc !== undefined) {
                desc = (
                    <i className="layui-edge layui-table-sort-desc" title="降序" onClick={e => this.handle(this.state.desc)}></i>
                )
            }
            sort = (
                <span className="layui-table-sort layui-inline" style={{marginTop:'-4px'}} >
                    {asc}
                    {desc}
                </span>
            )
        }
        return  <Fragment>{name}{sort}</Fragment>;
    }

    handle(sort) {
        this.props.sort && this.props.sort(sort);
    }
}
export default TableSort;