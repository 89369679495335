import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";

class RuleService extends BaseService
{
    requestUrls = {
        url:'/rule/list',
        addOrEditUrl:'/rule/addOrEdit',
        detailUrl:'/rule/detail',
        deleteUrl:'/rule/delete',
        sortUrl:'/rule/sort'
    }

    getList(suc) {
        this.makeAppends(['path']).post(
            this.requestUrls.url,
            {},
            suc
        )
    }

    getAll(suc) {
        this.getList((data) => {
               let first = this.getFirst(data);
            for(var k of first) {
                k.children = this.getChildren(k.id, data);
            }
            suc(first);
        })
    }

    getCanForParent(suc) {
        this.getAll((data) => {
            let list = [];
            for(var k of data) {
                list.push(k);
                for(var j of k.children) {
                    list.push(j);
                }
            }

            suc(list);
        })
    }

    getMenu(suc) {
        this.getList((list) => {
            let menu = this.getFirst(list, true);
            for(const k of menu) {
                k.children = this.getChildren(k.id, list, true);
            }

            this.auth.getRules((data) => {
                let ids = [];
                let ruleList = [];
                for(const k of data) {
                    ids.push(k.id);
                }
                for(const k of menu) {
                    if (ids.indexOf(k.id) > -1) {
                        ruleList.push(k);
                    }
                }
                for(const k of ruleList) {
                    var listChildren = [];
                    for(var son of k.children) {
                        var sonChildren = [];
                        for(var grand of son.children) {
                            if (ids.indexOf(grand.id) > -1) {
                                sonChildren.push(grand);
                            }
                        }
                        if (ids.indexOf(son.id) > -1) {
                            son.children = sonChildren;
                           listChildren.push(son);
                        }
                    }
                    k.children = listChildren;
                }
                suc(ruleList);
            }, true)
        })
    }

    getFirst(data, isMenu = false) {
        let menu = [];
        for(var k of data) {
            if ((! isMenu && ! k.pid) || (isMenu && ! k.pid && k.isMenu)) {
                menu.push(k);
            }
        }
        return menu;
    }

    getChildren(id, data, isMenu = false) {
        let children = [],
            rest = [];
        for(var k of data) {
            if ((! isMenu && k.pid === id) || (isMenu && k.pid === id && k.isMenu)) {
                children.push(k);
            } else {
                rest.push(k);
            }
        }
        if (rest.length) {
            for(const k of children) {
                k.children = [];
                for(var j of rest) {
                    if ((! isMenu && j.pid === k.id) || (isMenu && j.pid === k.id && j.isMenu)) {
                        k.children.push(j);
                    }
                }
            }
        }
        return children;
    }

    getSiblings(pid, data) {
        let list = [];
        for(var k of data) {
            if (k.pid === pid) {
                list.push(k);
            }
        }
        return list;
    }

    siblingsSort(data) {
        let sorts = [],
            list = [],
            ids = [];
        for(let k of data) {
            sorts.push(k.sort);
        }
        sorts = sorts.sort();
        for(let k in sorts) {
            for(var j of data) {
                if (j.sort === sorts[k] && ids.indexOf(j.id) < 0) {
                    list.push(j);
                    ids.push(j.id);
                }
            }
        }
        return list;
    }

    save(model, suc, err) {
        let validator = new Validator(),
            rules = [
                ['name', 'required', '请输入名称']
            ];

        model = this.app.htmlArrayToJson(model);

        if (! validator.validate(rules, model)) {
            return err(validator.getError());
        }
        this.post(
            this.requestUrls.addOrEditUrl,
            model,
            (res) => {
                if (model.sorts) {
                    let lenth = Object.keys(model.sorts).length,
                        func,
                        i = 0;


                    for(var k in model.sorts) {
                        i ++;
                        if (i === lenth) {
                            func = () => {
                                suc(res);
                            }
                        }
                        this.sort(
                            {id:k.replace('id', ''), sort:model.sorts[k]},
                            func
                            )
                    }
                } else {
                    suc(res);
                }
            },
            err
        )
    }

    sort(model, suc, err) {
        this.post(
            this.requestUrls.sortUrl,
            model,
            suc,
            err
        )
    }

    getDetail(id, suc) {
        this.post(
            this.requestUrls.detailUrl,
            {id:id},
            suc
        )
    }

    delete(id, suc, err) {
        this.post(
            this.requestUrls.deleteUrl,
            {id:id},
            suc,
            err
        )
    }

    getIconAttribute(val) {
        return JSON.parse(val || "{}") || {};
    }

    getPathAttribute(val, model) {
        return model.viewPath;
    }
}
export default RuleService;
