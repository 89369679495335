import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";
import File from "../../../../vendor/framework/file";

export default class TypeService extends BaseService {
    requestUrls = {
        url: '/type/list',
        save: '/type/save',
        detail: '/type/detail',
        delete: '/type/delete',
        all:'/type/all'
    }

    states = ['停用', '正常'];

    appends = ['stateDup'];

    file = new File();

    getAll(suc, data) {
        this.request({
            url:this.requestUrls.all,
            data,
            success:suc
        })
    }

    save(model, suc, err) {
        var validator = new Validator(),
            rules = [
                ['pid', 'required', '请选择父级'],
                ['name', 'required', '请填写名称'],
                ['icon', 'required', '请上传图标'],
            ];
        if (!validator.validate(rules, model)) {
            return err(validator.getError());
        }

        model.icon = this.file.filter(model.icon);
        this.request({
            url: this.requestUrls.save,
            data: model,
            success: suc,
            error: err
        })
    }

    getDetail(id, suc) {
        this.request({
            url: this.requestUrls.detail,
            data: {id: id},
            success: suc
        })
    }

    delete(id, suc, err) {
        this.request({
            url: this.requestUrls.delete,
            data: {id: id},
            success: suc
        })
    }

    getStateDupAttribute() {
        return this.states[this.attributes['status']];
    }

    getIconAttribute() {
        return this.file.make(this.attributes['icon']);
    }

    getParentNameAttribute() {
        return this.attributes['parentName'] || '顶级';
    }
}
