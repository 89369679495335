import style from "./slides.module.css";
import { Fragment } from 'react';
import View from "../../../../vendor/framework/view";

class Video extends View
{
    boot(props) {
        this.state = {
            inputName:props.inputName || 'video',
            canDel:props.canDel,
            process:false,
            msg:'',
            btnClass:props.btnClass || 'uploadVideoBtn',
            getFirstFrame: props.getFirstFrame !== undefined ? props.getFirstFrame : true,
            model:{
                src:props.src || '',
            }
        }
    }

    componentWillReceiveProps(props) {
        this.setModel({
            src:props.src || ''
        })
    }

    componentDidMount() {
        /* global layui,layer */
        let self = this;
        layui.use(['form', 'element'], () => {
            var element = layui.element;

            var config = {
                btn:'.'+this.state.btnClass,
                fileType:'video',
                async:true,
                start() {
                    self.setState({
                        process:true
                    })
                },
                process(complete, trans) {
                    self.setState({
                        msg:trans
                    })
                    element.progress('progressBar', parseInt(complete)  + '%')
                },
                error() {
                    element.progress('progressBar', 0  + '%');
                    self.setState({
                        msg:'服务器出错了'
                    })
                },
                fileErr(msg) {
                    layer.msg(msg);
                },
                success(res) {
                    if (res.code === 200) {
                        self.handleVideo(res.data.resutlt);
                        self.setState({
                            process:false
                        })
                    } else {
                        self.setState({
                            msg:res.msg
                        })
                        layer.msg(res.msg);
                    }
                }
            }

            if (self.props.max) {
                config.maxSize = self.props.max;
                config.fileErr = (msg) => {
                    layer.msg(msg);
                }
            }

            //上传视频
            self.app.upload(config);
        })
    }

    render() {
        var video,
            del,
            process,
            src =  this.state.model.src;

        if (this.app.isVideo(src)) {
            if (this.state.canDel) {
                del = (
                    <span className={"layui-icon layui-icon-close "+style["close-btn"]} onClick={e => this.removeVideo()} title={"删除"}></span>
                )
            }
            video = (
                <div className={style.imgBox}>
                    <div className={style.imgDiv}>
                        <video src={src} style={{width:'180px',height:'180px'}} className={this.state.btnClass+'Video'} controls='control' onCanPlayThrough={e => this.onCanPlayThrough(e)}></video>
                        <input type="hidden" name={this.state.inputName} value={src} />
                    </div>
                    {del}
                </div>
            )
        }
        if (this.state.process) {
            process = (
                <div className={style.process} id="process">
                    <div className="layui-progress" lay-showPercent="yes" lay-filter="progressBar">
                        <div className="layui-progress-bar layui-bg-red" lay-percent="0%"></div>
                    </div>
                    <span className="speed-text">{this.state.msg}</span>
                </div>
            )
        }

        return (
            <Fragment>
                <button style={{float: 'left'}} type="button" className={"layui-btn "+this.state.btnClass}>选择视频</button>
                {
                    this.props.tipExtentions && this.props.tipExtentions()
                }
                <div style={{height: 'auto',width: '100%',float: 'left',marginTop: '5px'}}>
                    {video}
                    <img src={this.state.model.firstFrame} alt=""/>
                </div>
                {process}
            </Fragment>
        );
    }

    removeVideo() {
        this.setModel({src:'', target : null}, '', () => {
            this.props.handle && this.props.handle(this.state.model, this.state.inputName);
        })
    }

    handleVideo(src) {
        let model = {
            src:src
        };

        this.setModel(model, '', () => {
            this.props.handle && this.props.handle(this.state.model, this.state.inputName);
        });
    }

    onCanPlayThrough(e) {
        let video = e.target,
            model = {
            target:video
        };

        this.setModel(model, '', () => {
            this.props.handle && this.props.handle(this.state.model, this.state.inputName);
        })
    }
}

export default Video;
