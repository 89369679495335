import TopicService from "../../../services/topic";
import Table from "../../layout/table";
import View from "../../../../../vendor/framework/view";
import TypeService from "../../../services/type";

export default class TopicListLayout extends View {

    state = {
        list:[]
    }

    service() {
        return TopicService;
    }

    render() {
        return (
            <Table
                stateInit={this.state}
                service={TopicService}
                searchInit={{
                    keywords: {name: '关键字', placeholder: '请输入关键字', enter: true},
                    tid: {name: '类型', type: 'select', data: this.state.list, option: ['id', 'name']},
                    status: {name: '状态', type: 'select', data: this.server.states, default: ''},
                }}
                fieldsInit={{
                    id: 'ID',
                    title: {name:'标题', type:'component', component: item => (<div title={item.title} style={{width:'200px', whiteSpace: 'nowrap',textOverflow: 'ellipsis',overflow: 'hidden'}}>{item.title}</div>)},
                    typeName:'类型',
                    contentDup:'内容',
                    sort:'排序',
                    stateDup:'状态',
                    createdAt: '添加时间'
                }}
                btnInit={{
                    add: {name: '添加', click: this.add.bind(this)},
                }}
                oprationInit={{
                    edit: {name: '修改', icon: ['edit', 'normal'], click: this.edit.bind(this)},
                    delete: {name: '删除', icon: ['delete', 'danger'], click: this.delete.bind(this)}
                }}
            />
        )
    }

    componentDidMount() {
        /* global layui,layer */
        layui.use(['form'], () => {
            this.server.getService(TypeService).getAll(data => {
                let list = [{id: '', name : '全部'}].concat(data);
                this.setState({
                    list: list
                });
            })
        })
    }

    add(table) {
        let item = table.state.data.list[0],
            tid = 0;
        if (item) {
            tid = item.tid;
        }
        this.layOpen({
            title: '添加题目',
            content: `/admin/topic/add?tid=${tid}`,
            form: true,
            area: ['900px', '90%'],
            yes: (field, index) => {
                table.server.save(field, () => {
                    layer.msg('添加成功!');
                    layer.close(index);
                    table.loadData();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    edit(val, table) {
        this.layOpen({
            title: '修改题目',
            content: '/admin/topic/edit/' + val.id,
            form: true,
            area: ['900px', '90%'],
            yes: (field, index) => {
                field.id = val.id;
                this.server.save(field, () => {
                    layer.msg('修改成功!');
                    layer.close(index);
                    table.loadData();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    delete(model, table) {
        layer.confirm('您真的要删除 ' + model.title + ' 吗?', () => {
            this.server.delete(model.id, () => {
                layer.msg('删除成功!');
                table.pageReload();
            }, (msg) => {
                layer.msg(msg);
            })
        })
    }
}
