import Base from "../../../base";
import RoleService from "../../../../services/admin/role";
import RuleService from "../../../../services/admin/rule";
import style from "../../rule/list/index.module.css";
import {Fragment} from "react";
import Form from "../../../layout/form";

class RoleEdit extends Base
{
    boot() {
        this.state = {
            model:{
                name:'',
                ruleIds:[],
                status:1
            },
            data:[]
        }
    }

    service() {
        return RoleService;
    }

    componentDidMount() {
        /* global layui */
        layui.use(['form'], () => {
            let form = layui.form;
            this.server.getDetail(this.getPropsParams('id'), (res) => {
                this.server.getService(RuleService).getAll((data) => {
                    this.setState({
                        data:data,
                        model:res
                    }, () => {
                        form.render();
                    })
                })
            })
        })
    }

    render() {
        return (
            <Form
                model={this.state.model}
                fields={{
                    name:'角色',
                    rules:{
                        name:'权限范围',
                        type:'itemComponent',
                        component:this.rules(),
                    },
                    status:{name:'状态', type: 'select', data:this.server.states},
                    desc:{name:'描述', type:'textarea'}
                }}
            />
        );
    }

    rules() {
        return (
            <ul>
                {
                    this.state.data.map((rule, index) => {
                        return (
                            <li key={index} style={{width: '100%',height: 'auto', display: 'block', marginBottom: '20px'}}>
                                <div className={style.tip}><p>页面路径:{rule['view_path']},<p>接口路径:{rule['api_view']},</p></p>描述:{rule['desc']}</div>
                                <input type="checkbox" checked={this.state.model.ruleIds.indexOf(rule['id']) > -1} name="ruleIds[]" title={rule['name']}
                                       value={rule['id']} lay-filter="rules" />
                                {
                                    rule['children'] && rule['children'].length ?
                                        (
                                            <div style={{marginLeft: '20px'}}>
                                                {
                                                    rule['children'].map((son, ind) => {
                                                        return (
                                                            <div style={{marginLeft:'28px'}}>
                                                                <div key={ind} className={style.tip}><p>页面路径:{son['view_path']},</p><p>接口路径:{son['api_view']},</p><p>描述:{son['desc']}</p></div>
                                                                <input type="checkbox" checked={this.state.model.ruleIds.indexOf(son['id']) > -1} name="ruleIds[]" title={son['name']}
                                                                       className="son" value={son['id']} lay-filter="rules" />
                                                                {
                                                                    son['children'] ?
                                                                        (
                                                                            <div style={{display: 'inline'}}>
                                                                                {
                                                                                    son['children'].map((v, i) => {
                                                                                        return (
                                                                                            <Fragment>
                                                                                                <div key={i} className={style.tip}><p>页面路径:{v['view_path']},<p>接口路径:{v['api_view']},</p></p>描述:{v['desc']}</div>
                                                                                                <input type="checkbox" name="ruleIds[]"
                                                                                                       checked={this.state.model.ruleIds.indexOf(v['id']) > -1}
                                                                                                       title={v['name']} className="son"
                                                                                                       value={v['id']} />
                                                                                            </Fragment>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        )
                                                                        :''
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                        :''
                                }
                            </li>
                        )
                    })
                }
            </ul>
        )
    }
}

export default RoleEdit;
