import Service from '../../../../vendor/framework/service';
import Auth from "../auth";

class BaseService extends Service
{
    auth = new Auth();

    notLogin(response) {
        /* global layer */
        layer.msg(response.data.msg);
        this.auth.logout();
        this.app.setItem(this.auth.lastUrl, window.location.pathname);
        this.app.redirect(this.auth.path, 1000);
    }

    notRule(response) {
        layer.msg(response.data.msg);
    }
}
export default BaseService;
