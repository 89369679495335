import Route from '../vendor/framework/route';
import Login from "../app/admin/views/login";
import Index from "../app/admin/views/index";
import UserList from "../app/admin/views/user/list";
import AdminList from "../app/admin/views/administraitor/admin/list";
import AdminAdd from "../app/admin/views/administraitor/admin/add";
import AdminEdit from "../app/admin/views/administraitor/admin/edit";
import AdminInformationDetail from "../app/admin/views/administraitor/admin/information/list";
import AdminInformationEdit from "../app/admin/views/administraitor/admin/information/edit";
import RoleList from "../app/admin/views/administraitor/role/list";
import RoleAdd from "../app/admin/views/administraitor/role/add";
import RoleEdit from "../app/admin/views/administraitor/role/edit";
import RuleList from "../app/admin/views/administraitor/rule/list";
import RuleAdd from "../app/admin/views/administraitor/rule/add";
import AuthMiddleware from "../app/admin/middlewares/auth";
import GuestMiddleware from "../app/admin/middlewares/guest";
import RuleIcon from "../app/admin/views/administraitor/rule/icon";
import RuleEdit from "../app/admin/views/administraitor/rule/edit";
import RoleChooseList from "../app/admin/views/administraitor/role/choose";
import Error from "../app/admin/views/index/error";
import TypeList from "../app/admin/views/type/list";
import TypeAdd from "../app/admin/views/type/add";
import TypeEdit from "../app/admin/views/type/edit";
import TopicList from "../app/admin/views/topic/list";
import TopicAdd from "../app/admin/views/topic/add";
import TopicEdit from "../app/admin/views/topic/edit";

var namespace = '/admin';

var paths = [
    {
        path: '/login',
        component: Login,
        middleware: [new GuestMiddleware()]
    },
    {
        path: '/',
        middleware: [new AuthMiddleware()],
        children: [
            {
                path: '/index',
                component: Index
            },
            {
                path:'/error',
                component: Error
            },
            {
                path:'/',
                middleware:[],
                children:[
                    {
                        path: '/user/list',
                        component: UserList
                    },
                    {
                        path: '/admin/list',
                        component: AdminList
                    },
                    {
                        path: '/admin/add',
                        component: AdminAdd
                    },
                    {
                        path: '/admin/edit/:id',
                        component: AdminEdit
                    },
                    {
                        path: '/admin/information',
                        component: AdminInformationDetail
                    },
                    {
                        path: '/admin/password/edit',
                        component: AdminInformationEdit
                    },
                    {
                        path: '/role/list',
                        component: RoleList
                    },
                    {
                        path: '/role/add',
                        component: RoleAdd
                    },
                    {
                        path: '/role/edit/:id',
                        component: RoleEdit
                    },
                    {
                        path:'/role/choose/list',
                        component: RoleChooseList
                    },
                    {
                        path: '/rule/list',
                        component: RuleList
                    },
                    {
                        path:'/rule/icon',
                        component: RuleIcon
                    },
                    {
                        path: '/rule/add/:pid?',
                        component: RuleAdd
                    },
                    {
                        path: '/rule/edit/:id',
                        component: RuleEdit
                    },
                    {
                        path: '/type/list',
                        component: TypeList
                    },
                    {
                        path: '/type/add',
                        component: TypeAdd
                    },
                    {
                        path: '/type/edit/:id',
                        component: TypeEdit
                    },
                    {
                        path: '/topic/list',
                        component: TopicList
                    },
                    {
                        path: '/topic/add',
                        component: TopicAdd
                    },
                    {
                        path: '/topic/edit/:id',
                        component: TopicEdit
                    }
                ]
            },
        ]
    },

];

export default class Admin extends Route {
    constructor(props) {
        super(props);
        this.namespace = namespace;
        this.paths = paths;
    }
}
