import Table from "../../layout/table";
import View from "../../../../../vendor/framework/view";
import TypeService from "../../../services/type";

export default class TypeListLayout extends View
{
    service() {
        return TypeService;
    }

    render() {
        return (
            <Table
                service={TypeService}
                searchInit={{
                    keywords: {name: '关键字', placeholder:'请输入关键字', enter: true},
                    status:{name: '状态', type:'select', data: this.server.states, default:''},
                }}
                fieldsInit={{
                    id:{name:'ID', sort:{asc:'asc', desc:'desc'}},
                    name:'名称',
                    parentName:'父级',
                    icon:{name:'图标', 'type':'image'},
                    sort:{name: '排序'},
                    stateDup:'状态',
                    createdAt:'添加时间'
                }}
                btnInit={{
                    add:{name:'添加', click:this.add.bind(this)},
                }}
                oprationInit={{
                    edit:{name:'修改', icon:['edit', 'normal'], click:this.edit.bind(this)},
                    delete:{name:'删除', icon:['delete', 'danger'], click:this.delete.bind(this)}
                }}
            />
        )
    }

    componentDidMount() {
        /* global layui,layer */
        layui.use(['form'], () => {

        })
    }

    add(table) {
        this.layOpen({
            title:'添加类型',
            content:'/admin/type/add',
            form:true,
            area:['900px', '90%'],
            yes: (field, index) => {
                table.server.save(field, () => {
                    layer.msg('添加成功!');
                    layer.close(index);
                    table.loadData();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    edit(val, table) {
        this.layOpen({
            title:'修改类型',
            content:'/admin/type/edit/'+val.id,
            form:true,
            area:['900px', '90%'],
            yes: (field, index) => {
                field.id = val.id;
                this.server.save(field, () => {
                    layer.msg('修改成功!');
                    layer.close(index);
                    table.loadData();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        })
    }

    delete(model, table) {
        layer.confirm('您真的要删除 ' + model.name + ' 吗?', () => {
            this.server.delete(model.id, () => {
                layer.msg('删除成功!');
                table.pageReload();
            }, (msg) => {
                layer.msg(msg);
            })
        })
    }
}

