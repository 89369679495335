import Base from "../../../base";
import { Fragment } from 'react';
import style from './index.module.css';
import RuleService from "../../../../services/admin/rule";

class RuleList extends Base
{
    boot() {
        this.state = {
            list:[]
        }
    }

    service() {
        return RuleService;
    }

    loadData() {
        this.server.getAll((data) => {
            this.setState({
                list:data
            })
        })
    }

    componentDidMount() {
        /* global layui,layer */
        layui.use(['form'], () => {
            this.loadData();
        })
    }

    render() {
        return (
            <div className={"layui-fluid"}>
                <div className={"layui-card"}>
                    <div className={"layui-card-body"}>
                        <div>
                            <button className={"layui-btn layuiadmin-btn-useradmin"} onClick={e => this.add()}>添加</button>
                        </div>
                        {
                            this.state.list.map((rule, index) => {
                                return (
                                    <ul key={index}>
                                        <li style={{display: 'block',width: '100%',height:'38px', background: '#9ad6c3',marginTop: '20px'}}>
                                            <button type="button"
                                                    onClick={e => this.edit(rule)}
                                                    className={"layui-btn layui-btn-radius layui-btn-primary tips"}
                                                    onMouseOver={e => this.mouseOver(index)} onMouseLeave={e => this.mouseLeave(index)}>
                                                {
                                                    rule.showDesc ? (
                                                        <div className={style.tip}><p>页面路径:{rule['viewPath'] || '无'},<p>接口路径:{rule['apiPath'] || '无'},</p></p>描述:{rule['desc']}</div>

                                                    ):''
                                                }
                                                {rule['name']}
                                            </button>
                                            <button type="button"
                                                    className={"layui-btn layui-btn-primary layui-btn-sm "+style.layNoMargin}
                                                    style={{marginLeft:0}}
                                                    onClick={e => this.add(rule)}
                                                    title="添加" >
                                                <i className={"layui-icon"}>&#xe654;</i>
                                            </button>
                                            <button type="button"
                                                    className={"layui-btn layui-btn-primary layui-btn-sm"} style={{marginLeft:0}}
                                                    onClick={e => this.delete(rule)}
                                                    title="删除">
                                                <i className={"layui-icon"}>&#xe640;</i>
                                            </button>
                                        </li>
                                        {
                                            rule['children'] ?
                                                (
                                                    <ul style={{width: '99.4%',height: 'auto',background: '#c8e4ec',padding: '5px'}}>
                                                        {
                                                            rule['children'].map((son, k) => {
                                                                return (
                                                                    <Fragment>
                                                                        <li className={style.children} key={k} style={{width: 'auto',height:'auto',marginRight: '5px',marginLeft: '20px',marginBottom: '6px'}}>
                                                                            <button type="button" onMouseOver={e => this.mouseOver(index, k)} onMouseLeave={e => this.mouseLeave(index, k)} onClick={e => this.edit(son)} className={"layui-btn layui-btn-primary layui-btn-sm tips " +style.layNoMargin} title="修改">
                                                                                {
                                                                                    son.showDesc ? (
                                                                                        <div className={style.tip}><p>页面路径:{son['viewPath'] || '无'},<p>接口路径:{son['apiPath'] || '无'},</p></p>描述:{son['desc']}</div>

                                                                                    ):''
                                                                                }
                                                                                {son['name']}
                                                                            </button>
                                                                            <button type="button" className={"layui-btn layui-btn-primary layui-btn-sm " +style.layNoMargin} style={{marginLeft:'-4px'}} onClick={e => this.add(son)} title="添加">
                                                                                <i className="layui-icon">&#xe654;</i>
                                                                            </button>
                                                                            <button type="button" style={{marginLeft:'-4px'}} onClick={e => this.delete(son)} className={"layui-btn layui-btn-primary layui-btn-sm"} title="删除">
                                                                                <i className={"layui-icon"}>&#xe640;</i>
                                                                            </button>
                                                                        </li>
                                                                        {
                                                                            son['children'] ?
                                                                                (
                                                                                    son['children'].map((v, i) => {
                                                                                        return (
                                                                                            <li key={i} className={style.children} style={{width: 'auto',height:'auto'}}>
                                                                                                <div className={style.childLine}>--</div>
                                                                                                <button onMouseOver={e => this.mouseOver(index, k, i)} onMouseLeave={e => this.mouseLeave(index, k, i)} type="button" onClick={e => this.edit(v)} className={"layui-btn layui-btn-primary layui-btn-sm tips " +style.layNoMargin} title="修改">
                                                                                                    {
                                                                                                        v.showDesc ? (
                                                                                                            <div className={style.tip}><p>页面路径:{v['viewPath'] || '无'},<p>接口路径:{v['apiPath'] || '无'},</p></p>描述:{v['desc']}</div>

                                                                                                        ):''
                                                                                                    }
                                                                                                    {v['name']}
                                                                                                </button>
                                                                                                <button type="button" onClick={e => this.delete(v)} style={{marginLeft:0}} className={"layui-btn layui-btn-primary layui-btn-sm"} title="删除">
                                                                                                    <i className={"layui-icon"}>&#xe640;</i>
                                                                                                </button>
                                                                                            </li>
                                                                                        )
                                                                                    })
                                                                                ) :''
                                                                        }
                                                                    </Fragment>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                ) :''
                                        }
                                    </ul>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }

    mouseOver(index, k = -1, v = -1) {
        let list = this.state.list,
            item = list[index];
        if (k != -1) {
            item = item.children[k];
        }
        if (v != -1) {
            item = item.children[v];
        }

        item.showDesc = true;
        this.setState({
            list:list
        })
    }

    mouseLeave(index, k = -1, v = -1) {
        let list = this.state.list,
            item = list[index];
        if (k != -1) {
            item = item.children[k];
        }
        if (v != -1) {
            item = item.children[v];
        }

        item.showDesc = false;
        this.setState({
            list:list
        })
    }

    add(model) {
        let id = model ? model.id : '';
        this.layOpen({
            title:  model ? '为 '+model.name +' 添加子权限' :'添加权限'
            ,content: '/admin/rule/add/'+id
            ,form:true
            ,area:['800px', '90%']
            ,yes: (data, index) => {
                this.server.save(data, () => {
                    layer.msg('添加成功!');
                    layer.close(index);
                    this.loadData();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        });
    }

    edit(model) {
        this.layOpen({
            title:  '修改 ' + model.name
            ,content: '/admin/rule/edit/'+model.id
            ,form:true
            ,area:['800px', '90%']
            ,yes: (data, index) => {
                data.id = model.id;
                this.server.save(data, () => {
                    layer.msg('修改成功!');
                    layer.close(index);
                    this.loadData();
                }, (msg) => {
                    layer.msg(msg);
                })
            }
        });
    }

    delete(model) {
        layer.confirm('您真的要删除 ' + model.name + ' 吗?', () => {
            this.server.delete(model.id, () => {
                layer.msg('删除成功!');
                this.loadData();
            }, (msg) => {
                layer.msg(msg);
            })
        })
    }
}
export default RuleList;
