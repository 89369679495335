import axios from "axios"
import Config from "./config";
import App from "./app";

class Request
{
	app = new App();

	config = new Config();

	axios = null;

	type = 'post';

	dataType = 'JSON';

	data = {};

	url = '';

	driver = 'axiosPromise';

	headers = {
		'Content-Type':'application/json',
		'Accept':'application/json'
	};

	confParams = {};

	constructor(axiosStart = true) {
		if (axiosStart) {
			this.axios = axios.create({
				baseURL:this.config.getServerUrl()
			})
		}
	}

	setUrl(url) {
		this.url = url;
	}

	setMethod(type) {
		this.type = type;
		return this;
	}

	setHeaders(headers) {
		this.headers = headers;
		return this;
	}

	setData(data) {
		this.data = data;
		return this;
	}

	getIp() {

	}

	getData() {
		this.data.ip = this.ip;
		return this.data;
	}

	setConfig(conf) {
		this.confParams = conf;
		for(var k in conf) {
			var method = 'set' + (k.charAt(0).toUpperCase() + k.slice(1));
			if (typeof this[method] === 'function') {
				this[method](conf[k]);
			}
		}
		return this;
	}

	send() {
		return this[this.driver]();
	}

	nativePromise() {
		return new Promise((resolve, reject) => {
			this.native({
				url:this.app.getServerUrl(this.url),
				data:this.getData(),
				headers:this.headers,
				success:(res) => {

				}
			})
		})
	}

	axiosPromise() {
		this.responseInterceptor();
		return this['axios'+ (this.type.charAt(0).toUpperCase() + this.type.slice(1))]();
	}

	axiosPost() {
		return this.axios.post(this.url, this.getData(), {
			headers: this.headers,
			onUploadProgress: progressEvent => {
				typeof this.confParams.onUploadProgress === 'function' && this.confParams.onUploadProgress(progressEvent);
			}
		});
	}

	axiosGet() {
		return this.axios.get(this.url, {
			params: this.data,
			headers: this.headers
		});
	}

	responseInterceptor() {
		this.axios.interceptors.response.use(response => {
			return response;
		})
	}

	native(config) {
		var xhr = new XMLHttpRequest(),
			loaded = 0,
			ot = 0,
			// eslint-disable-next-line
			total = 0,
			oloaded = 0;

		xhr.upload.onprogress = progress;
		xhr.onload = progressSuccess;

		xhr.open(config.type || 'POST', config.url, config.async ? true : false);

		if (config.headers) {
			for(var k in config.headers) {
				xhr.setRequestHeader(k, config.headers[k]);
			}
		}

		var form = config.form || new FormData();
		var data = config.data;
		if (data) {
			// eslint-disable-next-line
			for(var k in data) {
				form.append(k, data[k]);
			}
		}

		try {
			xhr.send(form);
		} catch (err) {
			console.log(err.message)
		}

		xhr.onloadend = config.end;

		var start = config.start;
		if (start) {
			start();
		}

		if (config.error) {
			xhr.onerror = config.error;
			if (config.timeout) {
				xhr.timeout = config.timeout;
				xhr.ontimeout = config.error;
			}
		}

		function progressSuccess (event) {
			if (xhr.readyState === 4 && xhr.status === 200) {
				var response = JSON.parse(event.currentTarget.responseText);
				var trans = '已完成';
				var process = config.process;
				if (process) {
					process(100, trans, 0);
				}

				var success = config.success;
				if (success) {
					success(response, event, config.item);
				}
			} else {
				var error = config.error;
				if (error) {
					error(event,config.item);
				}
			}
		}
		function progress(event) {
			loaded = event.loaded / event.total * 100 - 1;
			loaded = loaded > 1 ?  loaded : 0;
			var complete = Math.round(loaded).toFixed(2);
			var nt = new Date().getTime();//获取当前时间
			var pertime = (nt-ot)/1000; //计算出上次调用该方法时到现在的时间差，单位为s
			ot = new Date().getTime(); //重新赋值时间，用于下次计算
			var perload = event.loaded - oloaded; //计算该分段上传的文件大小，单位b
			oloaded = event.loaded;//重新赋值已上传文件大小，用以下次计算
			//上传速度计算
			var speed = perload/pertime;//单位b/s
			var bspeed = speed;
			var units = 'b/s';//单位名称
			if(speed/1024 > 1){
				speed = speed/1024;
				units = 'k/s';
			}
			if(speed/1024/1024 > 1){
				speed = speed/1024;
				units = 'M/s';
			}
			speed = speed.toFixed(1);
			//剩余时间
			var resttime = ((event.total-event.loaded)/bspeed).toFixed(1);
			// eslint-disable-next-line
			if (complete == 99) {
				var trans = '服务器处理中...'
			} else {
				// eslint-disable-next-line
				var trans = '传输速度：'+speed+units+',剩余时间：'+resttime+'s';
			}
			// eslint-disable-next-line
			var speedfunc = config.speed;
			if (speedfunc) {
				speedfunc(trans,resttime);
			}

			var process = config.process;
			if (process) {
				process(complete, trans, resttime);
			}
		}
	}

}
export default Request;
