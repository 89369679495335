import Base from "../../base";
import TypeService from "../../../services/type";
import Form from "../../layout/form";

export default class TypeAdd extends Base {
    boot() {
        this.state = {
            model: {
                title:'',
                pid: 0,
                icon: '',
                sort: 1,
                status: 1
            },
            list:[]
        }
        /* global layer */
    }

    service() {
        return TypeService;
    }

    componentDidMount() {
        this.server.getAll(data => {
            let list = [{
                id: 0,
                name: '顶级'
            }].concat(data);
            this.setState({
                list: list
            });
        }, {id: 0})
    }

    render() {
        return (
            <Form
                model={this.state.model}
                fields={{
                    name:'名称',
                    pid:{name:'父级', type:'select', data: this.state.list, option:['id', 'name']},
                    icon:{name:'图标', type: 'image'},
                    sort:{name:'排序', type:'number'},
                    status:{name:'状态', type: 'select', data:this.server.states}
                }}
            />
        );
    }
}

