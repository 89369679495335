import Config from "./config";

class App {

	config = new Config();

	getServerUrl(url = '') {
		if (url) {
			let str = url.substr(0, 1);
			if (str === '/') {
				url = url.substr(1);
			}
		}
		return this.config.getServerUrl() + url;
	}

	redirect(url, time = 1) {
		let str = url.substr(0, 1);
		if (str === '/') {
			url = url.substr(1);
		}
		if (url.indexOf('http') === -1) {
			url = this.getHost() + '/'+ url;
		}
		setTimeout(() => {
			this.skip(url);
		}, time)
	}

	reload(time = 1000) {
		setTimeout(() => {
			this.skip(window.location.href);
		}, time)
	}

	skip(url) {
		window.location.href = url;
	}

	setItem(key, value) {
		window.localStorage.setItem(key, value);
	}

	getItem(key) {
		return window.localStorage.getItem(key);
	}

	removeItem(key) {
		window.localStorage.removeItem(key);
	}

	pullItem(key) {
		var value = this.getItem(key);
		this.removeItem(key);
		return value;
	}

	htmlArrayToJson(form) {
		var model = {};

		for(var k in form) {
			var val  = form[k];
			if (k.match(/\[*\]/)) {
				var fieldArr = k.replace(/\]/g, '').split('['),
					key1 = fieldArr[0],
					key2 = fieldArr[1],
					key3 = fieldArr[2],
					obj = model[key1],
					isNumber = typeof Number(key2) === 'number' && !isNaN(key2);
				if (fieldArr.length === 2) {
					if (isNumber) {
						obj =  obj || [];
						obj.push(val);
					} else {
						obj =  obj || {};
						obj[key2] = val;
					}
				} else if (fieldArr.length === 3) {
					obj = obj || (isNumber ? [] : {});
					var last = obj[key2] || (typeof Number(key3) === 'number' && !isNaN(key3) ? [] : {});
					last[key3] = val;
					obj[key2] = last;
				}
				model[key1] = obj;
			} else {
				model[k] = val;
			}
		}
		return model;
	}

	delHtmlTag(str) {
		return str ? str.replace(/<[^>]+>/g,"").replace(/&nbsp;/g, '') : '';
	}

	url(url) {
		return this.getHost() + url;
	}

	getHost() {
		return window.location.protocol + '//' + window.location.host;
	}

	getUrlParams(key) {
		let data = window.location.search.replace('?', '').split('&'),
			params = {};
		for(var k in data) {
			var val = data[k].split('=');
			params[val[0]] = val[1];
		}
		if (key) {
			return params[key];
		}
		return params;
	}

	ueditorImgBlockForData(data) {
		for(var k in data) {
			data[k] = this.ueditorImgBlock(data[k]);
		}
		return data;
	}

	ueditorImgBlock(data) {
		var reg = /<p(\s*?)([A-Z|a-z|0-9|=|'|"|:]*?)><img(.*?)<\/p>/g;
		if (typeof data === 'string' && Boolean(data)) {
			var imgs = data.match(reg);
			if (imgs instanceof Array) {
				for(var j in imgs) {
					var img = imgs[j];
					var newImg = img.replace(/<p(.*?)>|<\/p>/g, '');
					if (! newImg.match(/<img style/)) {
						newImg = newImg.replaceAll('<img', '<img style="display:block;"');
					}
					data = data.replace(img, newImg);
				}
			}
		}
		return data;
	}

	formatDate(numb, format) {
		var date = new Date(parseInt(numb) * 1000);
		var YY = date.getFullYear() + format;
		var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + format;
		var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
		var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
		var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
		var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
		return YY + MM + DD +" "+hh + mm + ss;
	}

	isVideo(src) {
		let formats = this.config.upload.videoFormats;
		src = src || '';
		let srcArr = src.split('.'),
			ext = srcArr[srcArr.length - 1];
		ext = ext ? ext.toLowerCase() : '';
		return formats.indexOf(ext) >= 0;
	}

	// 下划线转换驼峰
	toHump(name) {
		return name.replace(/\_(\w)/g, function(all, letter){
			return letter.toUpperCase();
		});
	}

	// 驼峰转换下划线
	toLine(name) {
		return name.replace(/([A-Z])/g,"_$1").toLowerCase();
	}

	jsonToHump(json) {
		return JSON.parse(this.toHump(JSON.stringify(json)));
	}

	objKeyToHump(obj) {
		let json = {};
		if (obj) {
			for(let k in obj) {
				let value = obj[k];
				if (typeof value === 'object' && !value instanceof Array) {
					value = this.objKeyToHump(value);
				}
				json[this.toHump(k)] = value;
			}
		}
		return json;
	}
}

export default App;
