import Service from '../../../../vendor/framework/service';
import App from "../../../../vendor/framework/app";
import Validator from "../../../../vendor/framework/validator";

class Auth extends Service
{
	app = new App();

	requestUrls = {
	    login:'auth/login',
        userDetail:'auth/user/detail',
        userRulesUrl:'auth/rules'
    }

	field = 'interviewAdminToken';
	lastUrl = 'interviewAdminLastUrl';
	path = '/admin/login';
    rememberKey = 'interviewHistory';
    userKey = 'interviewAdmin';
    ruleKey = 'interviewAdminRules';

	signIn(model, suc, err) {
	    let self = this,
            validator = new Validator(),
            rules = [
                ['mobile', 'required', '请输入用户名'],
                ['password', 'required', '请输入密码']
            ];
	    if (! validator.validate(rules, model)) {
	        return err(validator.getError());
        }
        if (! model.jigsawFlag) {
            return err('滑块错误!');
        }
	   this.request({
            url:this.requestUrls.login,
            data:{username:model.mobile,password:model.password},
           success(res) {
               if (model.keepState) {
                   model.mobileDup = model.mobile;
                   self.setRemember(model);
                   self.setHistory(model);
               } else {
                   self.removeRemember();
                   self.removeHistory(model.mobile);
               }
               self.getUserDetail(res.token, (resp) => {
                   if (resp) {
                       self.setUser(resp);
                       self.login(res);
                       suc(res);
                   }
               })
           },
           error:err
        })
    }

    getRules(func, reset = false) {
	    let self = this,
            rules = this.app.getItem(this.ruleKey);
	    if (! reset && rules) {
	        return func && func(JSON.parse(rules));
        }

        this.request({
            url:this.requestUrls.userRulesUrl,
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + this.getToken(),
            },
            success(res) {
                self.setRules(res);
                func && func(res);
            }
        })
    }

    setRules(data) {
	    this.app.setItem(this.ruleKey, JSON.stringify(data));
    }

    getUserDetail(token, suc) {
	    this.request({
            url:this.requestUrls.userDetail,
            loading:false,
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + token
            },
            success:suc
        })
    }

	check() {
		return Boolean(this.getToken());
	}

	login(data) {
		return this.app.setItem(this.field, data.token);
	}

	logout() {
		return this.app.removeItem(this.field);
	}

	getToken() {
		return this.app.getItem(this.field);
	}

    setUser(user) {
        this.app.setItem(this.userKey, JSON.stringify(user));
    }

    getUser(key) {
        let user = this.app.getItem(this.userKey);
        if (user) {
            user = JSON.parse(user);
            if (key) {
                return user[key];
            }
            return user;
        }
    }

	getLastUrl(defaultUrl) {
	    return this.app.pullItem(this.lastUrl) || defaultUrl;
    }

    noPassByMobile(mobile) {
        if (mobile) {
            var pat = /(\d{3})\d*(\d{4})/;
            return mobile.replace(pat,'$1****$2');
        }
    }

    getRemember(suc) {
        let val = this.app.getItem(this.rememberKey);
        if (val) {
            suc(JSON.parse(val));
        }
    }

    setRemember(data) {
        this.app.setItem(this.rememberKey, JSON.stringify(data));
    }

    removeRemember() {
        this.app.removeItem(this.rememberKey);
    }

    getHistoryList() {
       let list = this.app.getItem(this.historyKey);
       if (list) {
           return JSON.parse(list);
       }
    }

    setHistory(secrets = {}) {
        let list = this.getHistoryList() || {};
        if (secrets.mobile && !list.hasOwnProperty(secrets.mobile)) {
            list[secrets.mobile] = secrets;
            this.app.setItem(this.historyKey, JSON.stringify(list));
        }
    }

    removeHistory(phone) {
        let list = this.getHistoryList();
        if (list && list.hasOwnProperty(phone)) {
            delete list[phone];
            if (JSON.stringify(list) === '{}') {
                this.app.removeItem(this.historyKey);
            } else {
                this.app.setItem(this.historyKey, JSON.stringify(list));
            }
        }
    }

    getLikeHistory(number) {
        let list = this.getHistoryList(),
            data = [];
        if (list) {
            for(var k in list) {
                if (k.indexOf(number) >= 0) {
                    data.push(list[k]);
                }
            }
        }
        return data;
    }
}
export default Auth;
