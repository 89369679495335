import View from "../../../../vendor/framework/view";
import {Fragment} from 'react';

class Editor extends View {
    boot(props) {
        this.state = {
            inputName: props.inputName || 'content',
            model: {
                content: props.content || '',
            },
            width:props.width || '90%',
            height:props.height || '300px'
        }

        this.detailObj = '';

        this.requireJs([
            '/ueditor/ueditor.config.js',
            '/ueditor/ueditor.all.min.js',
        ])
    }

    componentDidMount() {
        setTimeout(() => {
            this.detailObj = window.UE.getEditor("detail");
            if (this.state.model.content) {
                setTimeout(() => {
                    this.detailObj.setContent(this.state.model.content || '');
                }, 500)
            }
            this.detailObj.addListener('contentChange', () => {
                if (typeof this.props.contentChange === 'function') {
                    let content = this.detailObj.getContent();
                    this.props.contentChange(content, this.state.inputName);
                    this.setModel(this.state.inputName, content);
                }
            })
        }, 100)
    }

    componentWillReceiveProps(props) {
        this.state.model.content = props.content;
        if (this.detailObj) {
            setTimeout(() => {
                this.detailObj.setContent(props.content || '');
            }, 100)
        }
    }

    render() {
        return (
            <Fragment>
                <script id={"detail"} type="text/plain" style={{width: this.state.width, height: this.state.height}}></script>
                <textarea style={{display: 'none'}} name={this.state.inputName} onChange={e => this.onChangeValue(e)}
                          value={this.state.model[this.state.inputName]}></textarea>
            </Fragment>
        );
    }

    onChangeValue(e) {

    }

}

export default Editor;
