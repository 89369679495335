import Base from "../../base";
import TopicService from "../../../services/topic";
import Form from "../../layout/form";
import TypeService from "../../../services/type";

export default class TopicAdd extends Base {
    boot() {
        this.state = {
            model: {
                title:'',
                tid:'',
                content:'',
                sort:1,
                status: 1
            },
            list:[]
        }
        /* global layer */
    }

    componentDidMount() {
        this.server.getService(TypeService).getAll(data => {
            let tid = this.app.getUrlParams('tid');
            this.setModel('tid', tid);
            this.setState({
                list: data
            });
        })
    }

    service() {
        return TopicService;
    }

    render() {
        return (
            <Form
                model={this.state.model}
                fields={{
                    title:'标题',
                    tid:{name:'类型', type: 'select', data: this.state.list, option:['id', 'name']},
                    content:{name:'内容', type:'editor'},
                    sort:{name:'排序', type:'number'},
                    status:{name:'状态', data: this.server.states}
                }}
            />
        );
    }
}

