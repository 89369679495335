import style from './slides.module.css';
import $ from "jquery";
import { Fragment } from 'react'
import View from "../../../../vendor/framework/view";
import File from "../../../../vendor/framework/file";

export default class Slides extends View {
    boot(props) {
        this.state = {
            list:props.list || [],
            inputName: props.inputName || 'slideshows[]',
            canUpload:props.canUpload !== undefined ? props.canUpload : true,
            btnClass:props.btnClass !== undefined ? props.btnClass : 'uploadSlideBtn',
            origin:props.origin !== undefined ? props.origin : false,
        }
    }

    componentDidMount() {
        let self = this;
        /* global layui,layer */
        layui.use(['form'], () => {

            let file = new File(),
                config = {
                btn:'.'+this.state.btnClass,
                fileType:'image',
                fileErr(msg) {
                    layer.msg(msg);
                },
                multiple:true,
                success(res) {
                    if (res.code === 200) {
                        var list = self.state.list;
                        list.push(file.make(res.data.src));
                        self.setState({
                            list:list
                        })
                        self.handleList(list.length - 1, list);
                    } else {
                        layer.msg(res.msg);
                    }
                }
            };
            if (self.props.max) {
                config.maxSize = self.props.max;
                config.fileErr = (msg) => {
                    layer.msg(msg);
                }
            }
            if (! this.props.origin) {
                // 上传轮播图
                file.upload(config);
            } else {
                $('.'+this.state.btnClass).click(function() {
                    $('#file').val('');
                    $('#file').click();
                })
            }

            $('#file').change(function() {
                let files = $(this)[0].files,
                    srcs = [];
                for(let k of files) {
                    srcs.push(window.URL.createObjectURL(k));
                }
                self.setState({
                    list:srcs
                })
            })
        })
    }

    componentWillReceiveProps(props) {
        this.setState({
            list:props.list
        })
    }

    render() {
        var uploadBtn;
        if (this.state.canUpload) {
            uploadBtn = (
                <Fragment>
                    <button style={{float: 'left'}} type="button" className={"layui-btn "+this.state.btnClass}>选择图片</button>
                    {
                        this.props.tipExtentions && this.props.tipExtentions()
                    }
                </Fragment>
            )
        }
        return (
            <Fragment>
                {uploadBtn}
                <div style={{height: 'auto',width: '100%',float: 'left',marginTop: '5px'}} onDragEnter={e => this.dragenter(e)} onDragOver={e => this.dragenter(e)}>
                    {
                        this.state.origin ? (<input type="file" id={"file"} name={this.state.inputName} multiple="multiple" style={{display:'none'}} />) :''
                    }
                    {
                        this.state.list.map((val, index) => {
                            return (
                                <div key={index}>
                                    <div className={style.imgBox + ' '+ style.moveTo}>
                                        <div className={style.imgDiv + ' slideshows'} slideindex={index} draggable={true} onDragEnd={this.onDragEnd} onDrag={e => this.onDrag(e)}>
                                            <img className="psimg" slide={"true"} src={val} slideindex={index} />
                                            {
                                                this.props.origin ? '': (
                                                    <input type="hidden" name={this.state.inputName} value={val} />
                                                )
                                            }
                                        </div>
                                        <span className={"layui-icon layui-icon-close "+style["close-btn"]} onClick={e => this.removeSlideShows(index)} title={"删除"}></span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </Fragment>

        );
    }

    dragenter(e) {
        e.preventDefault();
    }

    onDrag(e) {
        var elem = document.elementFromPoint(e.clientX, e.clientY),
            toIndex = elem ? elem.getAttribute('slideindex') : '',
            obj = $(e.target);
        if (obj.prop('tagName') === 'IMG') {
            obj = obj.closest('div');
        }
        if (elem) {
            if (typeof toIndex === 'string') {
                if (! obj.hasClass(style.borderGreen)) {
                    obj.addClass(style.borderGreen);
                }
                if (obj.hasClass(style.borderRed)) {
                    obj.removeClass(style.borderRed);
                }
            } else {
                if (obj.hasClass(style.borderGreen)) {
                    obj.removeClass(style.borderGreen);
                }
                if (! obj.hasClass(style.borderRed)) {
                    obj.addClass(style.borderRed);
                }
            }
        }
    }

    onDragEnd = e => {
        let cur = Number(e.target.getAttribute('slideindex')),
            slides = this.state.list,
            curImg = slides[cur],
            data = [],
            data1 = [],
            data2 = [],
            toIndex = document.elementFromPoint(e.clientX, e.clientY).getAttribute('slideindex'),
            obj = $(e.target);

        if (obj.prop('tagName') === 'IMG') {
            obj = obj.closest('div');
        }
        if (obj.hasClass(style.borderRed)) {
            obj.removeClass(style.borderRed);
        }
        if (obj.hasClass(style.borderGreen)) {
            obj.removeClass(style.borderGreen);
        }

        if (typeof toIndex === 'string') {
            toIndex = Number(toIndex);
            for(var k in slides) {
                if (k != cur) {
                    if(cur<toIndex){
                        if (k <= toIndex) {
                            data1.push(slides[k]);
                        } else {
                            data2.push(slides[k]);
                        }
                    }else{
                        if (k < toIndex) {
                            data1.push(slides[k]);
                        } else {
                            data2.push(slides[k]);
                        }
                    }
                }
            }
            for(var k in data1) {
                data.push(data1[k]);
            }
            data.push(curImg);
            for(var k in data2) {
                data.push(data2[k]);
            }
            this.handleList(toIndex, data);
            this.setState({'list':data});
        }
    }

    handleList(index, list) {
        if (typeof this.props.handleList === 'function') {
            this.props.handleList(index, list, this.state.inputName);
        }
    }

    removeSlideShows(index) {
        this.setState(state => {
            var slides = [],
                slideshows = state.list;
            for(var k in slideshows) {
                if (Number(k) !== Number(index)) {
                    slides.push(slideshows[k]);
                }
            }
            this.handleList(index, slides);
            return {
                list:slides
            }
        })
    }
}

