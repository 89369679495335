import Base from "../../../base";
import AdminService from "../../../../services/admin";
import RoleListLayout from "../../role/layout";
import RoleService from "../../../../services/admin/role";
import Form from "../../../layout/form";

class AdminEdit extends Base
{
    boot() {
        this.state = {
            model:{
                username:'',
                password:'',
                status:1,
                roleId:0,
                passwordConfirmation:''
            },
            role:''
        }
    }

    service() {
        return AdminService;
    }

    componentDidMount() {
        /* global layer */
        this.server.getDetail(this.getPropsParams('id'), (data) => {
            this.server.getService(RoleService).getDetail(data.roleId, (resp) => {
                this.setState({
                    model:data,
                    role:resp
                })
            })
        })
    }

    render() {
        return (
            <Form
                model={this.state.model}
                fields={{
                    username:'账号',
                    password:{name:'密码', type: 'password'},
                    passwordConfirmation:{name:'重复密码', type: 'password'},
                    roleId:{name:'角色', type:'choose', click: this.chooseRole.bind(this), component:(state) => {
                            return (
                                <RoleListLayout
                                    btnShow={false}
                                    show={state.model.roleId}
                                    list={this.state.role ? [this.state.role] : []}
                                    autoload={false}
                                    layuiFluid={false}
                                    layuiCardBody={false}
                                    layForm={false}
                                    layuiCardHeader={false}
                                    oprationShow={false}
                                    inputName={'roleId'}
                                    paging={false}
                                />
                            )
                        }},
                    status:{name:'状态', type:'select', data: this.server.states}
                }}
            />
        );
    }

    chooseRole() {
        this.layOpen({
            title:'选择角色',
            content:'/admin/role/choose/list',
            form: true,
            yes: (data, index) => {
                var field = this.app.htmlArrayToJson(data), //获取提交的字段
                    user = field.data,
                    role;

                if (! user || !user.length) {
                    layer.msg('请选择角色!');
                    return;
                }

                for(var k in user) {
                    role = JSON.parse(user[k]);
                }

                this.setState( state =>{
                    let model = state.model;
                    model.roleId = role.id;
                    return {
                        role:role,
                        model:model
                    }
                })
                layer.close(index)
            }
        })
    }
}

export default AdminEdit;
