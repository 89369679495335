import BaseService from "../base";
import Validator from "../../../../vendor/framework/validator";

export default class TopicService extends BaseService {
    requestUrls = {
        url: '/topic/list',
        save: '/topic/save',
        detail: '/topic/detail',
        delete: '/topic/delete'
    }

    states = ['停用', '正常'];

    appends = ['stateDup', 'contentDup'];

    save(model, suc, err) {
        var validator = new Validator(),
            rules = [];
        if (!validator.validate(rules, model)) {
            return err(validator.getError());
        }

        this.request({
            url: this.requestUrls.save,
            data: model,
            success: suc,
            error: err
        })
    }

    getDetail(id, suc) {
        this.request({
            url: this.requestUrls.detail,
            data: {id: id},
            success: suc
        })
    }

    delete(id, suc, err) {
        this.request({
            url: this.requestUrls.delete,
            data: {id: id},
            success: suc
        })
    }

    getStateDupAttribute() {
        return this.states[this.attributes['status']];
    }

    getContentDupAttribute() {
        var content = this.app.delHtmlTag(this.attributes['content']);
        var length = content.length;
        if (! content && this.attributes['content']) {
            if (this.attributes['content'].match(/<img/)) {
                return '--图片内容--';
            }
        }
        return length > 33 ? (content.substr(0, 30) + '...') : content;
    }
}
