import App from "../framework/app";

class Page
{
    app = new App();

    pageLimits = [10, 50, 100, 1000];

    layout = ['count', 'prev', 'page', 'next', 'limit', 'skip'];

    view = null;

    constructor(view) {
        this.view = view;
    }

    render(conf = {}) {

        if (this.view.server) {
            if (! conf.url && this.view.server && this.view.server.requestUrls) {
                conf.url = this.view.server.requestUrls.url;
            }

            if (conf.url && conf.url.indexOf('http') < 0) {
                conf.url = this.app.getServerUrl(conf.url);
            }

            conf.data = conf.data || {};

            var view = this.view;

            if (view.state.search) {
                for(var k in view.state.search) {
                    conf.data[k] = view.state.search[k];
                }
            }

            conf.data.pageNum = conf.pageNum || view.getPaging('pageNum');
            conf.data.pageSize = conf.pageSize || view.getPaging('pageSize');
            var reConf = Object.create(conf);

            reConf.success = (response) => {
                if (response) {
                    if (response.pages && response.pageNum > response.pages) {
                        conf.pageNum = response.pages;
                        return this.render(conf);
                    }
                    conf.success && conf.success(response);
                }

                this.loadPage(view.paging.id, response, (obj) => {
                    conf.pageNum = obj.curr;
                    conf.pageSize = obj.limit;
                    conf.jump && conf.jump(obj);
                    this.render(conf);
                })
            }
            return this.view.server.request(reConf);
        }
    }

    loadPage(id, data, jump) {
        /* global layui */
        var self =  this;
        layui.use(['laypage'], () => {
            layui.laypage.render({
                elem: id
                ,count: data.total, //数据总数，从服务端得到
                limit: data.pageSize,
                limits: data.pageLimits || this.pageLimits,
                layout: this.layout,
                curr: data.pageNum,
                jump(obj, first) {
                    if (! first) {
                        jump(obj);
                       self.view.setPaging({
                           pageNum:obj.curr,
                           pageSize:obj.limit
                       })
                    }
                    layui.form && layui.form.render();
                },
            });
        })
    }
}
export default Page;
