import Base from "../../base";
import TopicListLayout from "../layout";

export default class TopicList extends Base
{
    render() {
        return (
            <TopicListLayout />
        );
    }
}
